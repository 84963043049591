<!-- Version: 1.0 -->
<template>
  <div
    id="fp-button"
    :class="(variant ? variant : '') + (outline ? ' outline ' : '')"
    @click="$emit('click')"
  >
    <router-link v-if="routerTo" :to="routerTo">
      {{ text }}
    </router-link>
    <div v-else>
      {{ text }}
    </div>
  </div>
</template>
<script>
//TODO: állítható színek kezelése
export default {
  name: "fp-button",
  props: {
    variant: String, //primary, secondary
    outline: Boolean,
    text: String,
    routerTo: [String, Object],
  },
};
</script>
<style>
a:hover #fp-button,
a:hover #fp-button:hover,
#fp-button:hover {
  text-decoration-line: none;
  text-decoration-thickness: initial;
}
#fp-button a:hover,
#fp-button:hover a:hover,
#fp-button:hover {
  text-decoration-line: none;
  text-decoration-thickness: initial;
}
#fp-button {
  cursor: pointer;
  color: white;
  background: rgb(220, 220, 220);
  font-weight: 800;
  padding: 6px 15px;
  border-radius: 10px;

  -webkit-box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.46);
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.46);

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
#fp-button a {
  color: white;
}
#fp-button:hover {
  background-color: #ffffff80;
  color: gray;

  -webkit-box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.35);

  transition: 0.3s;
}
#fp-button:hover a {
  color: gray;
}
#fp-button.primary {
  background: var(--eh-secondary-4);
}
#fp-button.primary:hover {
  background: var(--eh-secondary-2);
}
#fp-button.secondary {
  background: var(--eh-primary-4);
}
#fp-button.secondary:hover {
  background: var(--eh-primary-2);
}
#fp-button.outline {
  border: solid 2px;
  padding: 4px 10px;
  background: transparent;
}
#fp-button.outline:hover {
  background: white;
}
#fp-button.outline.primary,
#fp-button.outline.primary a {
  color: var(--eh-secondary-4);
}
#fp-button.outline.primary:hover {
  background: var(--eh-secondary-2);
}
#fp-button.outline.primary:hover,
#fp-button.outline.primary:hover a {
  color: gray;
}
#fp-button.outline.secondary,
#fp-button.outline.secondary a {
  color: var(--eh-primary-4);
}
#fp-button.outline.secondary:hover {
  background: var(--eh-primary-2);
}
#fp-button.outline.secondary:hover,
#fp-button.outline.secondary:hover a {
  color: gray;
}
</style>